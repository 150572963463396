<template>
	<div>
		<van-nav-bar
			title="修改密碼"
			left-arrow
			fixed
			class="qjc-nav-bar"
			@click-left="$router.back()"
		/>
		
		<div class="change qjc-input-group">
			<h2 class="title qjc-texta-l qjc-c-dark">修改密碼</h2>
			<van-field
				placeholder="原密碼"
				v-model="oldPwd"
				type="password"
			/>
			<van-field
				placeholder="新密碼"
				v-model="newPwd"
				type="password"
			/>
			<van-button @click="changePwd" class="qjc-ftw-b qjc-c-primary qjc-borderc-primary qjc-fts-32 qjc-wid-100">確認修改</van-button>
		</div>
		
		<footer class="qjc-fixed qjc-wid-100">本產品目前僅適用於20-90周歲人群</footer>
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, Field, Button, Toast } from 'vant'
	Vue.use(NavBar)
	   .use(Field)
	   .use(Button)
	   .use(Toast);
	   
	import { pwdReg } from "@/js/utils.js"
	import { editPassWord } from '@/js/axios'
	   
	export default{
		name: 'userChangePwd',
		data (){
			return {
				oldPwd: '',//原密码
				newPwd: ''//新密码
			}
		},
		methods: {
			changePwd (){
				if(this.oldPwd.trim() == '' || !pwdReg(this.oldPwd)){
					Toast.fail('原密碼格式不正確');
				}else if(this.newPwd.trim() == '' || !pwdReg(this.newPwd)){
					Toast.fail('新密碼格式不正確');
				}else{
					editPassWord({
						password: this.oldPwd,
						newpassword: this.newPwd
					}).then(res => {
						if(res.status == 1){
							Toast.success(res.msg);
							this.$store.dispatch('exit').then(() => {
								this.$router.push('/login');
							});
						}else{
							Toast.fail(res.msg);
						}
					})
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.change{
		margin-top: 0.88rem;
		padding: 0.6rem 0.3rem;
		
		.title{
			font-size: 0.4rem;
		}
		.van-button{
			margin-top: 0.56rem;
			height: 0.78rem;
			line-height: 0.78rem;
			border-radius: 0.08rem;
		}
	}
	footer{
		font-size: 0.2rem;
		color: #C6CBD6;
		left: 0;
		bottom: 0.3rem;
	}
</style>
